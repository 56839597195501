import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';
import char from './char.png';
import bone from './Bone.png';
import gbone from './GBone.png';
import PalletIcon from './icons/pallet.png';
import DiscordIcon from './icons/discord.png';
import TwitterIcon from './icons/twitter.png';
import MediumIcon from './icons/medium.png';

const boardSize = 12; 
const initialSnake = [{ x: 6, y: 6 }];
const initialDirection = { x: 0, y: 0 };

const getRandomFoodPosition = () => ({
  x: Math.floor(Math.random() * boardSize),
  y: Math.floor(Math.random() * boardSize),
  type: Math.random() < 0.1 ? 'gbone' : 'bone',
});

function App() {
  const [snake, setSnake] = useState(initialSnake);
  const [direction, setDirection] = useState(initialDirection);
  const [food, setFood] = useState(getRandomFoodPosition);
  const [isPaused, setIsPaused] = useState(true);
  const [hasStarted, setHasStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const intervalRef = useRef(null);

  const handleKeyDown = useCallback((event) => {
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', ' '].includes(event.key)) {
      event.preventDefault();
    }
    changeDirection(event.key);
  }, [direction, isPaused, hasStarted, gameOver]);

  const changeDirection = (key) => {
    const newDirection = { ...direction };

    if (key === ' ') {
      if (gameOver) {
        resetGame();
      }
      setIsPaused(!isPaused);
      return;
    }

    if (key === 'ArrowUp' && direction.y === 0) {
      newDirection.x = 0;
      newDirection.y = -1;
    } else if (key === 'ArrowDown' && direction.y === 0) {
      newDirection.x = 0;
      newDirection.y = 1;
    } else if (key === 'ArrowLeft' && direction.x === 0) {
      newDirection.x = -1;
      newDirection.y = 0;
    } else if (key === 'ArrowRight' && direction.x === 0) {
      newDirection.x = 1;
      newDirection.y = 0;
    }

    if (!hasStarted) {
      setHasStarted(true);
      setIsPaused(false);
    }

    setDirection(newDirection);
  };

  const resetGame = () => {
    setSnake(initialSnake);
    setDirection(initialDirection);
    setScore(0);
    setGameOver(false);
    setHasStarted(false);
    setIsPaused(true);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (isPaused || !hasStarted || (direction.x === 0 && direction.y === 0) || gameOver) return;

    intervalRef.current = setInterval(() => {
      setSnake((prevSnake) => {
        const newSnake = [...prevSnake];
        const head = { ...newSnake[0] };
        head.x += direction.x;
        head.y += direction.y;

        const collision =
          head.x < 0 ||
          head.x >= boardSize ||
          head.y < 0 ||
          head.y >= boardSize ||
          newSnake.some(segment => segment.x === head.x && segment.y === head.y);

        if (collision) {
          clearInterval(intervalRef.current);
          setGameOver(true);
          return prevSnake;
        }

        newSnake.unshift(head);
        if (head.x === food.x && head.y === food.y) {
          if (food.type === 'gbone') {
            setScore(score + 10);
          } else {
            setScore(score + 1);
          }
          setFood(getRandomFoodPosition());
        } else {
          newSnake.pop();
        }

        return newSnake;
      });
    }, 200);

    return () => clearInterval(intervalRef.current);
  }, [direction, food, isPaused, score, hasStarted, gameOver]);

  return (
    <div className="App">
      <a href="https://www.craftycanines.xyz/" className="top-link">Back to Crafty Canines</a>
      <div className="score">Score: {score}</div>
      <div className="board">
        {Array.from({ length: boardSize }).map((_, row) => (
          <div key={row} className="row">
            {Array.from({ length: boardSize }).map((_, col) => {
              const isSnake = snake.some(segment => segment.x === col && segment.y === row);
              const isFood = food.x === col && food.y === row;
              return (
                <div key={col} className={`cell ${isSnake ? 'snake' : ''} ${isFood ? 'food' : ''}`}>
                  {isSnake && <img src={char} alt="snake" className="char" />}
                  {isFood && (
                    <img
                      src={food.type === 'gbone' ? gbone : bone}
                      alt={food.type}
                      className="food-img"
                    />
                  )}
                </div>
              );
            })}
          </div>
        ))}
        {gameOver && (
          <div className="game-over-message">
            <div>Game Over!</div>
            <div>Score: {score}</div>
            <button className="restart-button" onClick={resetGame}>Restart</button>
          </div>
        )}
      </div>
      <div className="controls">
        <button className="control-button" onClick={() => changeDirection('ArrowUp')}>▲</button>
        <div>
          <button className="control-button" onClick={() => changeDirection('ArrowLeft')}>◀</button>
          <button className="control-button" onClick={() => changeDirection('ArrowRight')}>▶</button>
        </div>
        <button className="control-button" onClick={() => changeDirection('ArrowDown')}>▼</button>
      </div>
      <div className="description">
        <p><img src={bone} alt="Bone" className="legend-img" /> Bone: 1 point</p>
        <p><img src={gbone} alt="Golden Bone" className="legend-img" /> Golden Bone: 10 points</p>
      </div>

      <footer className="app-footer">
        <div className="app-footer-links-container">
          <p>
            <a href="https://pallet.exchange/collection/crafty-canines" target="_blank" rel="noopener noreferrer" className="app-footer-btn-link">
              <img src={PalletIcon} alt="Pallet" className="app-footer-social-icon" />
              <span>Buy Now on Pallet</span>
            </a>
          </p>
          <p>
            <a href="https://discord.com/invite/NRmRcmJV6w" target="_blank" rel="noopener noreferrer" className="app-footer-btn-link">
              <img src={DiscordIcon} alt="Discord" className="app-footer-social-icon" />
              <span>Join our Discord</span>
            </a>
          </p>
          <p>
            <a href="https://x.com/crafty_canines" target="_blank" rel="noopener noreferrer" className="app-footer-btn-link">
              <img src={TwitterIcon} alt="Twitter" className="app-footer-social-icon" />
              <span>Follow us on Twitter/X</span>
            </a>
          </p>
          <p>
            <a href="https://medium.com/@craftycanines" target="_blank" rel="noopener noreferrer" className="app-footer-btn-link">
              <img src={MediumIcon} alt="Medium" className="app-footer-social-icon" />
              <span>Follow us on Medium</span>
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
